import { HealthInsuranceRepository } from 'repository/interfaces/health-insurance-repository'
import { LoadAllHealthInsuranceCategories } from 'domain/usecases/health-insurance/load-health-insurance-categories'

export class RemoteLoadHealthInsuranceCategories
  implements LoadAllHealthInsuranceCategories
{
  constructor(
    private readonly healthInsuranceRepository: HealthInsuranceRepository
  ) {}

  load(
    params: LoadAllHealthInsuranceCategories.Params
  ): Promise<LoadAllHealthInsuranceCategories.Model> {
    return this.healthInsuranceRepository.loadHealthInsuranceCategories(params)
  }
}
