import { LoadAllHealthInsuranceCategories } from 'domain/usecases/health-insurance/load-health-insurance-categories'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadHealthInsurancePlans: LoadHealthInsurancePlans
  loadHealthInsuranceCategories: LoadAllHealthInsuranceCategories
}

export class HealthInsuranceService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadHealthInsurancePlans(
    params: LoadHealthInsurancePlans.Params
  ): Promise<LoadHealthInsurancePlans.Model> {
    return this.services.loadHealthInsurancePlans.load(params)
  }
  async loadHealthInsuranceCategories(
    params: LoadAllHealthInsuranceCategories.Params
  ): Promise<LoadAllHealthInsuranceCategories.Model> {
    return this.services.loadHealthInsuranceCategories.load(params)
  }
}

export default HealthInsuranceService
