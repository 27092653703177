import React, { useEffect, useState } from 'react'

import Heading from 'presentation/shared/components/Heading'
import { Patient } from 'domain/entities/patient-model'
import { AddSurgicalOrderFormValues, formatEquipments } from '..'
import * as S from './styles'
import Button from 'presentation/shared/components/Button'
import ActualPage from 'presentation/shared/components/ActualPage'
import { useFormikContext } from 'formik'
import { Profile } from 'common/enum/profile'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import getDateFormattedByLocale from 'common/utils/getDateFormmatedByLocale'
import { DateLocales } from 'common/enum/date-locales'
import { isIsoDate } from 'common/utils/verify-iso-date'
import DocumentsArea from 'presentation/shared/components/DocumentsArea'
import { DocumentItem } from 'presentation/shared/components/DocumentsArea/DocumentsAreaModal'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import moment from 'moment-timezone'
import { documentList } from 'presentation/shared/pages/SurgeryDetails/documents'
import EditPatientDataModal, {
  ModifiedPatientData
} from 'presentation/secretary/components/EditPatientDataModal'
import { SurgicalStatusModel } from 'domain/entities/surgical-status-model'
import EditInsuranceModal, {
  ModifiedInsuranceData
} from 'presentation/secretary/components/EditInsuranceModal'
import { UpdateSurgicalOrderRegenerateGuides } from 'domain/usecases/surgical-order/update-surgical-order-regenerate-guides'
import SuccessModal from 'presentation/shared/components/Modal'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { toast } from 'react-toastify'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { PatientDocument } from 'common/enum/patient-document'
import { conformToMask } from 'react-text-mask'
import { cpfMask } from 'presentation/utils/masks'
import {
  MaritalStatus,
  getMaritalStatus
} from 'presentation/utils/default-marital-status'
import {
  getCharacter,
  getHospitalRegime
} from 'presentation/doctor/components/Forms/AddSurgicalOrder/ReviewOrder/ReservationInfoReview'
import Table from 'presentation/shared/components/Table'
import {
  cidColumns,
  procedureColumns
} from 'presentation/hospital/components/TussTable'
import { medicalTeamColumns } from '../SelectSurgeryInfo/MedicalTeamTable'
import { anesthetistColumns } from '../SelectSurgeryInfo/AnesthetistTeamTable'
import theme from 'presentation/styles/theme'
import { opmeColumns } from 'presentation/hospital/components/OpmeTable'
import { cmeColumns } from '../SelectSurgeryInfo/SelectCME'
import Divider from 'presentation/shared/components/Divider'
import { medicalTeamTypeDictionary } from 'presentation/utils/professional'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import { useHistory } from 'react-router'

type Props = {
  patient?: Patient
  formValues?: AddSurgicalOrderFormValues
  opme?: {
    description?: string
    quantity?: string
  }[]
  procedure?: {
    tuss_id?: string
    description?: string
    quantity?: string
  }[]
  goBack?: () => void
  onlyVisualization?: boolean
  profile?: Profile
  surgical_order_id?: number
  surgicalOrder?: SurgicalStatusModel[]
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
  updateSurgicalOrderRegenerateGuides?: UpdateSurgicalOrderRegenerateGuides
  loadHealthInsurance?: LoadHealthInsurancePlans
} & WithLoadingProps

function SurgicalOrderReview({
  goBack,
  onlyVisualization,
  surgical_order_id,
  loadSurgicalOrderDocument,
  surgicalOrder,
  updateSurgicalOrderRegenerateGuides,
  loadHealthInsurance,
  setIsLoading,
  profile
}: Props) {
  const { values, submitForm, isSubmitting } =
    useFormikContext<AddSurgicalOrderFormValues>()
  const history = useHistory()
  const [documents, setDocuments] = useState([] as DocumentItem[])
  const [patientEditModal, setPatientEditModal] = useState(false)
  const [insuranceEditModal, setInsuranceEditModal] = useState(false)
  const [handleSuccessModal, setHandleSuccessModal] = useState(false)
  const [insurances, setInsurances] = useState<HealthInsurancePlans[]>([])

  const handleDownloadDocumentFile = async (
    document_id: number
  ): Promise<File> => {
    if (surgical_order_id) {
      const surgicalOrderDocument = await loadSurgicalOrderDocument?.load(
        Number(surgical_order_id),
        document_id
      )
      if (surgicalOrderDocument) {
        return new File([surgicalOrderDocument?.data], '', {
          type: surgicalOrderDocument.contentType
        })
      }
    }
    return new File([''], '')
  }

  useEffect(() => {
    setDocuments(values?.documents_attached)
  }, [values?.documents_attached])

  const handleSubmit = async () => {
    if (surgical_order_id) {
      try {
        setIsLoading(true)
        await updateSurgicalOrderRegenerateGuides?.update({
          surgical_order_id: surgical_order_id,
          patient: values.patient,
          healthInsurance: values.healthInsurance
        })
      } catch (error: any) {
        toast.error(error)
      } finally {
        setHandleSuccessModal(true)
        setIsLoading(false)
      }
    }
  }

  const handlePatientData = (modifiedPatientData: ModifiedPatientData) => {
    setPatientEditModal(false)
    setHandleSuccessModal(!handleSuccessModal)
    values.patient.name = modifiedPatientData.name
    values.patient.birthday = modifiedPatientData.birthday
    values.patient.email = modifiedPatientData.email
    values.patient.gender = modifiedPatientData.gender
    values.patient.maritalStatus = modifiedPatientData.maritalStatus
    values.patient.phone = modifiedPatientData.phone
    values.patient.landlinePhone = modifiedPatientData.landlinePhone
  }

  const loadHealthInsurances = async (hospital_id: number) => {
    try {
      const insurances = await loadHealthInsurance?.load({
        hospital_id: hospital_id ?? 0,
        params: [
          'code',
          'description',
          'ansRegister',
          'plans {code, description, subPlan{code, description}}'
        ]
      })
      setInsurances(insurances ?? [])
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  const lateralityDictionary = {
    left: 'Esquerdo',
    right: 'Direito',
    bilateral: 'Bilateral',
    'not applicable': 'Não se aplica'
  }

  useEffect(() => {
    async function loadData() {
      try {
        await loadHealthInsurances(values.hospital.hospital_id)
      } catch (error: any) {
        toast.error(error.message)
      }
    }

    loadData()
  }, [])

  const handleInsuranceData = (
    modifiedInsuranceData: ModifiedInsuranceData
  ) => {
    setInsuranceEditModal(false)
    setHandleSuccessModal(!handleSuccessModal)
    values.healthInsurance.healthInsuranceName =
      modifiedInsuranceData.healthInsuranceName
    values.healthInsurance.healthInsuranceCode =
      modifiedInsuranceData.healthInsuranceCode
    values.healthInsurance.company = modifiedInsuranceData.company
    values.healthInsurance.healthCard = modifiedInsuranceData.healthCard
    values.healthInsurance.healthPlanCode = modifiedInsuranceData.healthPlanCode
    values.healthInsurance.healthPlanName = modifiedInsuranceData.healthPlanName
    values.healthInsurance.healthSubPlanName =
      modifiedInsuranceData.healthSubPlanName
    values.healthInsurance.healthSubPlanCode =
      modifiedInsuranceData.healthSubPlanCode
    values.healthInsurance.validThru = modifiedInsuranceData.validThru
  }

  const formattedCpf =
    values.patient?.cpf &&
    conformToMask(values.patient?.cpf, cpfMask)?.conformedValue

  const allocationDate =
    values?.allocation?.beginDateTime || values?.expectedDate[0]

  return (
    <S.Wrapper>
      <ActualPage
        text={onlyVisualization ? 'Voltar' : 'Editar'}
        onClick={goBack}
      />
      <Heading size="huge" color="primary" as="h1">
        Resumo do planejamento cirúrgico
      </Heading>
      <S.DataOverview>
        <div>
          <h6>Reserva selecionada: </h6>
          <span>
            {moment(allocationDate).utc(false).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      </S.DataOverview>
      {values?.patientRecord && (
        <S.DataOverview>
          <div>
            <h6>Prontuário : </h6>
            <span>{values?.patientRecord}</span>
          </div>
        </S.DataOverview>
      )}
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Médico e unidade
      </Heading>
      <S.DataOverview>
        <div>
          <h6>Médico: </h6>
          <span data-testid="doctor-name-span">
            {values.doctor?.name ? values.doctor?.name : 'Não informado'}
          </span>
        </div>
        <div>
          <h6>Unidade: </h6>
          <span data-testid="hospital-name-span">{values.hospital?.name}</span>
        </div>
        <div>
          <h6>CRM: </h6>
          <span data-testid="doctor-crm-span">
            {values.doctor?.crm ? values.doctor?.crm : 'Não informado'}
          </span>
        </div>
        <div>
          <h6>Sala: </h6>
          <span data-testid="surgery-center-span">
            {values.surgery_center?.description
              ? values.surgery_center?.description
              : 'Não informado'}
          </span>
        </div>
      </S.DataOverview>
      <S.HeadingWrapper>
        <Heading
          size="large"
          color="gray"
          as="h1"
          style={{ marginBottom: '12px', marginTop: '20px' }}
        >
          Dados do paciente
        </Heading>
        {surgicalOrder && surgicalOrder[0].status === 'REVIEW' && (
          <S.Edit onClick={() => setPatientEditModal(!patientEditModal)}>
            {' '}
            Editar{' '}
          </S.Edit>
        )}
      </S.HeadingWrapper>
      <S.DataOverview>
        <div>
          <h6>Paciente: </h6>
          <span data-testid="patient-name-span">{values.patient?.name}</span>
        </div>
        <div>
          <h6>E-mail: </h6>
          <span data-testid="patient-email-span">{values.patient?.email}</span>
        </div>
        <div>
          <h6>CPF: </h6>
          <span data-testid="patient-cpf-span">{formattedCpf}</span>
        </div>
        <div>
          <h6>Estado civil: </h6>
          <span data-testid="patient-cpf-span">
            {getMaritalStatus(values.patient?.maritalStatus as MaritalStatus)}
          </span>
        </div>
        <div>
          <h6>Data de nascimento: </h6>
          <span data-testid="patient-birthday-span">
            {values.patient?.birthday && isIsoDate(values.patient?.birthday)
              ? getDateFormattedByLocale(
                  values.patient?.birthday,
                  DateLocales.ptBr
                )
              : values.patient?.birthday}
          </span>
        </div>
        <div>
          <h6>Idade: </h6>
          <span data-testid="patient-age-span">
            {getAgeByBirthdayDate(values.patient?.birthday)}
          </span>
        </div>
        <div>
          <h6>Celular: </h6>
          <span data-testid="patient-phone-span">{values.patient?.phone}</span>
        </div>
        <div>
          <h6>Sexo: </h6>
          <span data-testid="patient-gender-span">
            {values.patient?.gender}
          </span>
        </div>
      </S.DataOverview>
      <S.HeadingWrapper>
        <Heading
          size="large"
          color="gray"
          as="h1"
          style={{ marginBottom: '12px', marginTop: '20px' }}
        >
          Dados do convênio
        </Heading>
        {surgicalOrder && surgicalOrder[0].status === 'REVIEW' && (
          <S.Edit onClick={() => setInsuranceEditModal(!patientEditModal)}>
            {' '}
            Editar{' '}
          </S.Edit>
        )}
      </S.HeadingWrapper>
      <S.DataOverview>
        <div>
          <h6>Convênio: </h6>
          <span data-testid="health-insurance-name-span">
            {values.healthInsurance?.healthInsuranceName}
          </span>
        </div>
        <div>
          <h6>Categoria do Convênio: </h6>
          <span data-testid="health-insurance-category-name-span">
            {values.healthInsurance?.categoryName}
          </span>
        </div>
        {values.healthInsurance?.company && (
          <div>
            <h6>Empresa: </h6>
            <span data-testid="health-insurance-company-span">
              {values.healthInsurance?.company}
            </span>
          </div>
        )}
        {values.healthInsurance?.healthPlanName && (
          <div>
            <h6>Plano: </h6>
            <span data-testid="health-insurance-plan-span">
              {values.healthInsurance?.healthPlanName}
            </span>
          </div>
        )}
        {values.healthInsurance?.healthCard && (
          <div>
            <h6>Nº carteirinha: </h6>
            <span data-testid="health-insurance-card-span">
              {values.healthInsurance?.healthCard}
            </span>
          </div>
        )}
        {values.healthInsurance?.healthSubPlanName && (
          <div>
            <h6>Subplano: </h6>
            <span data-testid="health-insurance-sub-plan-span">
              {values.healthInsurance?.healthSubPlanName}
            </span>
          </div>
        )}
        {values.healthInsurance?.validThru && (
          <div>
            <h6>Validade da carteira </h6>
            <span data-testid="health-insurance-valid-thru-span">
              {getDateFormattedByLocale(
                values.healthInsurance.validThru,
                DateLocales.ptBr
              )}
            </span>
          </div>
        )}
      </S.DataOverview>
      <Divider />
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Caráter da Reserva
      </Heading>
      <S.DataOverview
        style={{
          gridTemplateColumns: '1fr 1fr 1fr'
        }}
      >
        {values?.createdAt && (
          <div>
            <h6>Data Criação: </h6>
            <span>{moment(values?.createdAt).format('DD/MM/YYYY')}</span>
          </div>
        )}
        <div>
          <h6>Caráter: </h6>
          <span>{getCharacter(values?.hospitalizationType)}</span>
        </div>
        <div>
          <h6>Duração prevista: </h6>
          <span>
            {values?.expectedDuration
              ? values.expectedDuration + ' minutos'
              : 'Não informado'}
          </span>
        </div>
        <div>
          <h6>Uso de UTI: </h6>
          <span>{values?.requestedUtiDiaries ? 'sim' : 'não'}</span>
        </div>
        <div>
          <h6>Quantidade diárias de UTI: </h6>
          <span>
            {values?.requestedUtiDiaries
              ? values?.requestedUtiDiaries
              : 'Não informado'}
          </span>
        </div>
        <div>
          <h6>Regime hospitalar: </h6>
          <span>{getHospitalRegime(values?.hospitalizationMode)}</span>
        </div>
        <div>
          <h6>Paciente já internado?: </h6>
          <span>{values?.patientHospitalized ? 'sim' : 'não'}</span>
        </div>
        <div>
          <h6>Tipo de classificação do paciente: </h6>
          <span>{values?.patientClassificationType?.description}</span>
        </div>
        <div>
          <h6>Tipo de classificação da agenda: </h6>
          <span>{values?.agendaClassification?.description}</span>
        </div>
        <div>
          <h6>Origem do paciente: </h6>
          <span>{values?.patientOrigin?.description}</span>
        </div>
        <div>
          <h6
            style={{
              color: '#6F6F6F'
            }}
          >
            Tempo médio de internação:
          </h6>
          <span>{values?.hospitalizationAvgTimeInDays}</span>
        </div>
      </S.DataOverview>
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Procedimento
      </Heading>
      <S.DataOverview>
        <div>
          <h6>Cirurgia conjugada: </h6>
          <span>{values?.medicalTeamName ? 'Sim' : 'Não'}</span>
        </div>
        {values?.medicalTeamName && (
          <div>
            <h6>Nome da equipe: </h6>
            <span>{values?.medicalTeamName}</span>
          </div>
        )}
      </S.DataOverview>
      <Table
        style={{
          marginBottom: '2rem'
        }}
        checkbox={false}
        data={values?.procedures}
        columns={procedureColumns}
        pagination={false}
        noItemsDescription="Nenhum procedimento adicionado"
      />
      <S.DataOverview>
        <div>
          <h6>História clínica: </h6>
          <span>{values?.clinicalHistory}</span>
        </div>
      </S.DataOverview>
      <Table
        style={{
          marginBottom: '2rem'
        }}
        checkbox={false}
        data={values?.cids}
        columns={cidColumns}
        pagination={false}
        noItemsDescription="Nenhum CID adicionado"
      />
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Dados cirúrgicos
      </Heading>
      <S.DataOverview
        style={{
          gridTemplateColumns: '1fr 1fr 1fr 1fr'
        }}
      >
        <div>
          <h6>Cintilografia prévia: </h6>
          <span>{values?.preliminaryScintigraphyTime ? 'Sim' : 'Não'}</span>
        </div>
        <div>
          <h6>Lateralidade: </h6>
          <span>
            {lateralityDictionary[values.laterality] ?? 'Não informado'}
          </span>
        </div>
        <div>
          <h6>Congelação: </h6>
          <span>{values?.freezeTime ? 'Sim' : 'Não'}</span>
          {values?.freezeTime && (
            <>
              |<h6>Hora: </h6>
              <span>{values?.freezeTime}</span>
            </>
          )}
        </div>
        <div>
          <h6>Raio-X da peça: </h6>
          <span>{values?.xrayOfPieceTime ? 'Sim' : 'Não'}</span>
          {values?.xrayOfPieceTime && (
            <>
              |<h6>Hora: </h6>
              <span>{values?.xrayOfPieceTime}</span>
            </>
          )}
        </div>

        <div>
          <h6>Alergias: </h6>
          <span data-testid="allergy-span">
            {values?.allergy ?? 'Não informado'}
          </span>
        </div>
        {values.otherAllergies && (
          <div>
            <h6>Outras alergias: </h6>
            <span data-testid="other-allergies-span">
              {values.otherAllergies}
            </span>
          </div>
        )}
      </S.DataOverview>
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Equipe médica
      </Heading>
      <Table
        checkbox={false}
        data={values.medicalTeam?.map((medicalTeam) => ({
          ...medicalTeam,
          type: medicalTeamTypeDictionary[medicalTeam.type]
        }))}
        columns={medicalTeamColumns}
        pagination={false}
        noItemsDescription="Nenhum profissional adicionado"
        style={{ marginBottom: '2rem' }}
      />
      <S.DataOverview>
        <div>
          <h6>Anestesia: </h6>
          <span>{values?.anesthetistTeam?.length ? 'Externo' : 'Casa'}</span>
        </div>
      </S.DataOverview>
      {values?.anesthetistTeam?.length ? (
        <Table
          checkbox={false}
          data={values.anesthetistTeam}
          columns={anesthetistColumns}
          pagination={false}
          noItemsDescription="Nenhum médico anestesista adicionado"
          style={{
            marginBottom: '2rem'
          }}
        />
      ) : (
        <></>
      )}
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Banco de sangue
      </Heading>
      <S.DataOverview
        style={{
          gridTemplateColumns: '1fr 1fr 1fr'
        }}
      >
        <div>
          <h6>Tipagem sanguínea: </h6>
          <span>{values?.hasBloodType ? 'Sim' : 'Não'}</span>
        </div>
        <div>
          <h6>Hemoconcentrados: </h6>
          <span>{values?.hasHemoconcentrates ? 'Sim' : 'Não'}</span>
        </div>
        <div>
          <h6>Cell Saver: </h6>
          <span>{values?.cellSaverSpec ? 'Sim' : 'Não'}</span>
          {values?.cellSaverSpec && (
            <>
              |<h6>Especificação: </h6>
              <span>{values?.cellSaverSpec}</span>
            </>
          )}
        </div>
        <div>
          <h6>Quantidade de bolsas: </h6>
          <span>{values?.bloodBagQuantity || 0}</span>
        </div>
      </S.DataOverview>
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Equipamentos
      </Heading>
      <S.DataOverview
        style={{
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridGap: '.8rem',
          width: '80%'
        }}
      >
        {values?.equipments?.length ? (
          formatEquipments(values?.equipments, true).map(
            (equipment) =>
              equipment.enabled && (
                <>
                  <div
                    key={equipment.name}
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: theme.colors.primary,
                        display: 'inline-block',
                        borderRadius: '2px',
                        marginRight: '5px'
                      }}
                    ></div>
                    <span>{equipment.name} </span>
                  </div>
                  <div>
                    <h6>Especificação: </h6>
                    <span>{equipment.specification}</span>
                  </div>
                </>
              )
          )
        ) : (
          <div
            style={{
              gridColumn: 'span 4'
            }}
          >
            <span>Nenhum equipamento adicionado</span>
          </div>
        )}
      </S.DataOverview>
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        OPME
      </Heading>
      {values.opmes?.length ? (
        <>
          <Table
            checkbox={false}
            data={values.opmes}
            columns={opmeColumns}
            pagination={false}
            style={{ marginBottom: '2rem' }}
          />
          <S.ProvidersWrapper>
            {values.providers
              .filter((provider) => !!provider)
              .map((provider, index) => (
                <div key={index}>
                  <h6>Fornecedor {index + 1}: </h6>
                  <span data-testid={`${index}-provider-span`}>{provider}</span>
                </div>
              ))}
          </S.ProvidersWrapper>
        </>
      ) : (
        <div>
          <span>Nenhum OPME adicionado</span>
        </div>
      )}
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        Observações
      </Heading>
      <S.DataOverview>
        <div>
          <span>{values?.observations}</span>
        </div>
      </S.DataOverview>
      <Heading
        size="large"
        color="gray"
        as="h1"
        style={{ marginBottom: '12px', marginTop: '20px' }}
      >
        CME
      </Heading>
      {values.cmes?.length ? (
        <Table
          checkbox={false}
          data={values.cmes}
          columns={cmeColumns}
          pagination={false}
          noItemsDescription="Nenhum CME adicionado"
          style={{ marginBottom: '2rem' }}
        />
      ) : (
        <div>
          <span>Nenhum CME adicionado</span>
        </div>
      )}

      <S.DocumentsContainer>
        <DocumentsArea
          defaultDocuments={documents}
          isReviewOnly
          handleDownloadDocument={handleDownloadDocumentFile}
          hasDocuments={documents?.some((document) =>
            documentList.some(
              (docList) =>
                docList.type.includes(document.type) ||
                document.type === PatientDocument.EXAM_REPORT
            )
          )}
          canSee={values.canSee}
        />
      </S.DocumentsContainer>
      {surgicalOrder &&
      surgicalOrder[0].status === SurgicalOrderStatus.REVIEW &&
      profile !== Profile.SECRETARY ? (
        <>
          <Button
            type="button"
            style={{ marginTop: '30px', float: 'right' }}
            onClick={() =>
              history.push('/pedido/novo', {
                old_solicitation: surgical_order_id,
                startStep: 2
              })
            }
            data-testid="complete-surgical-order-button"
          >
            Completar cadastro
          </Button>
          <Button
            type="button"
            variant="outlined"
            style={{
              marginTop: '30px',
              float: 'right',
              marginRight: 20
            }}
            onClick={goBack}
            data-testid="cancel-edit-surgical-button"
          >
            Cancelar
          </Button>
        </>
      ) : (
        <Button
          type="button"
          style={{ marginTop: '30px', float: 'right' }}
          onClick={submitForm}
          disabled={isSubmitting}
          data-testid="create-surgical-button"
        >
          {onlyVisualization ? 'Voltar' : 'Criar planejamento'}
        </Button>
      )}
      {patientEditModal && (
        <EditPatientDataModal
          showModal={(show: boolean) => setPatientEditModal(show)}
          patientData={values.patient}
          changePatientData={(data: ModifiedPatientData) =>
            handlePatientData(data)
          }
        />
      )}
      {insuranceEditModal && (
        <EditInsuranceModal
          showModal={(show: boolean) => setInsuranceEditModal(show)}
          insuranceData={values.healthInsurance}
          insurances={insurances}
          changeInsuranceData={(data: ModifiedInsuranceData) =>
            handleInsuranceData(data)
          }
        />
      )}
      <SuccessModal
        title="Dados salvos com sucesso!"
        show={handleSuccessModal}
        close={() => {
          setHandleSuccessModal(false)
        }}
      />
    </S.Wrapper>
  )
}

export default WithLoading(SurgicalOrderReview)
