import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { HealthInsuranceRepository as IHealthInsuranceRepository } from 'repository/interfaces/health-insurance-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import {
  loadAllHealthInsurancePlansQuery,
  loadHealthInsuranceCategoriesQuery,
  loadHealthInsurancePlansQuery,
  loadHealthInsuranceQuery
} from 'repository/graphql/queries/index'
import { makeGraphQLVariable } from '../../graphql/utils/make-variables'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { LoadAllHealthInsurancePlans } from 'domain/usecases/health-insurance/load-all-health-insurance'
import { LoadAllHealthInsuranceCategories } from 'domain/usecases/health-insurance/load-health-insurance-categories'

export class HealthInsuranceRepository implements IHealthInsuranceRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async load(
    params: LoadHealthInsurance.Params
  ): Promise<LoadHealthInsurance.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadHealthInsurance.Model>

    const query = loadHealthInsuranceQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.search, 'search'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadHealthInsurance.Model
    }
  }

  async loadHealthInsurancePlans(
    params: LoadHealthInsurancePlans.Params
  ): Promise<LoadHealthInsurancePlans.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadHealthInsurancePlans.Model>

    const query = loadHealthInsurancePlansQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.hospital_id, 'hospital_id'),
          ...makeGraphQLVariable(params.onlyInOvermind, 'onlyInOvermind')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadHealthInsurancePlans.Model
    }
  }

  async loadAllHealthInsurancePlans(
    params: LoadAllHealthInsurancePlans.Params
  ): Promise<LoadAllHealthInsurancePlans.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadAllHealthInsurancePlans.Model>

    const query = loadAllHealthInsurancePlansQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadAllHealthInsurancePlans.Model
    }
  }

  async loadHealthInsuranceCategories(
    params?: LoadAllHealthInsuranceCategories.Params
  ): Promise<LoadAllHealthInsuranceCategories.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadAllHealthInsuranceCategories.Model>

    const query = loadHealthInsuranceCategoriesQuery()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.healthInsuranceId, 'healthInsuranceId')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadAllHealthInsuranceCategories.Model
    }
  }
}
