import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import { useFormik } from 'formik'
import Button from 'presentation/shared/components/Button'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import Heading from 'presentation/shared/components/Heading'
import Logo from 'presentation/shared/components/Logo'
import Modal from 'presentation/shared/components/Modal'
import PasswordField from 'presentation/shared/components/PasswordField'
import SupportText from 'presentation/shared/components/SupportText'
import TextButton from 'presentation/shared/components/TextButton'
import TextField from 'presentation/shared/components/TextField'
import { phoneMask } from 'presentation/utils/masks'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { codeFields } from './fields'

import * as S from './styles'
import SelectField from 'presentation/shared/components/SelectField'
import {
  FakePasswordInput,
  FakeUserInput
} from 'presentation/shared/components/FakeInput'

type HospitalPasswordRecoveryFormProps = {
  type: 'phone' | 'email'
  getCodeUseCase?: GetPasswordRecoveryCode
  changePasswordUseCase?: ChangePassword
  getCodeValues?: GetPasswordCodeFormValues
  setPasswordValues?: SetPasswordFormValues
}

export default function HospitalPasswordRecoveryForm({
  getCodeUseCase,
  changePasswordUseCase,
  getCodeValues = {} as GetPasswordCodeFormValues,
  setPasswordValues = {} as SetPasswordFormValues,
  type
}: HospitalPasswordRecoveryFormProps) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [elRefs, setElRefs] = useState([] as HTMLInputElement[])
  const [showGettedCodeModal, setShowGettedCodeModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const history = useHistory()

  React.useEffect(() => {
    setElRefs((elRefs) => {
      return [...Array(codeFields.length)].map((_, i) => elRefs[i])
    })
  }, [codeFields.length])

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 1) {
      setPasswordForm.setFieldValue(e.target.name, e.target.value)
    }
  }

  const changeFocus = (e: React.KeyboardEvent, index: number) => {
    if (
      index < elRefs.length - 1 &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete'
    ) {
      elRefs[index + 1].focus()
    }
  }

  const getCodeForm = useFormik({
    initialValues: getCodeValues,
    validateOnMount: true,
    validationSchema:
      type === 'phone' ? PhoneValidationSchema : EmailValidationSchema,
    onSubmit: async (values) => {
      try {
        await getCodeUseCase?.getPasswordRecoveryCode({
          type: values.type,
          field:
            type === 'phone' ? values.field.replace(/\D+/g, '') : values.field,
          role: values.role
        })
        setShowGettedCodeModal(true)
        setTimeout(() => {
          carousel.slideNext()
        }, 1000)
      } catch (err: any) {
        toast.error(err.message)
      }
    }
  })

  const setPasswordForm = useFormik({
    initialValues: setPasswordValues,
    validationSchema: setPasswordValidationSchema,
    onSubmit: async (values) => {
      try {
        await changePasswordUseCase?.changePassword({
          code: `${values.codeChar1}${values.codeChar2}${values.codeChar3}${values.codeChar4}`,
          password: values.password,
          recover: {
            type: type,
            field:
              type === 'phone'
                ? getCodeForm.values?.field.replace(/\D+/g, '')
                : getCodeForm.values.field,
            role: getCodeForm.values?.role
          }
        })
        setShowConfirmationModal(true)
      } catch (err: any) {
        carousel.slideTo(1)
        toast.error(err.message)
      }
    }
  })

  return (
    <>
      <S.Wrapper>
        <Logo
          size="huge"
          style={{
            paddingTop: '80px',
            display: 'inline-block'
          }}
        />
        <Carousel
          state={carousel}
          setState={setCarousel}
          slidesPerView={1}
          touch={false}
        >
          <S.Step>
            <header>
              <Heading as="h1">
                Escolha uma maneira de <strong>alterar sua senha</strong>
              </Heading>
              <SupportText>
                {/* Enviaremos um código para seu celular ou e-mail para auxiliarmos
                no processo */}
                Enviaremos um código para seu e-mail para auxiliarmos no
                processo
              </SupportText>
            </header>
            <div>
              {type === 'phone' ? (
                <TextField
                  name="field"
                  label="Seu telefone:"
                  mask={phoneMask}
                  defaultValue={getCodeForm.values.field}
                  onInputChange={getCodeForm.handleChange('field')}
                  onBlur={getCodeForm.handleBlur('field')}
                  error={
                    getCodeForm.touched.field
                      ? getCodeForm.errors.field
                      : undefined
                  }
                />
              ) : (
                <TextField
                  label="Seu e-mail:"
                  onInputChange={getCodeForm.handleChange('field')}
                  onBlur={getCodeForm.handleBlur('field')}
                  error={
                    getCodeForm.touched.field
                      ? getCodeForm.errors.field
                      : undefined
                  }
                />
              )}
              <SelectField
                style={{ marginTop: '12px' }}
                label="Perfil:"
                value={getCodeForm.values?.role || ''}
                onChange={getCodeForm.handleChange}
                name="role"
                id="role"
                items={[
                  {
                    label: 'Administrador',
                    value: 'ADMIN'
                  },
                  {
                    label: 'CRMO',
                    value: 'CRMO'
                  },
                  {
                    label: 'Central de Guias',
                    value: 'CRMO'
                  },
                  {
                    label: 'Recepção',
                    value: 'RECEPTIONIST'
                  },
                  { label: 'Internação', value: 'HOSPITALIZATION' },
                  { label: 'Cadastro Médico', value: 'DOCTOR_REGISTER' },
                  {
                    label: 'Diretor Médico',
                    value: 'DIRECTOR_DOCTOR_REGISTER'
                  },
                  {
                    label: 'Admin Setorial CRMO',
                    value: 'ADMIN_SECTORIAL_CRMO'
                  }
                ]}
              />
            </div>
            <Button
              disabled={!!getCodeForm.errors.field}
              data-testid="btn-get-code"
              type="button"
              onClick={getCodeForm.submitForm}
              style={{ marginTop: '24px' }}
            >
              Enviar
            </Button>
          </S.Step>
          <S.Step>
            <header>
              <Heading as="h1">
                Escolha uma maneira de <strong>alterar sua senha</strong>
              </Heading>
              <SupportText>Digite o código abaixo</SupportText>
            </header>
            <S.CodeInputWrapper>
              <TextButton size="small" style={{ alignSelf: 'flex-start' }}>
                Código
              </TextButton>
              <S.CodeInput>
                {codeFields.map((input, index) => (
                  <TextField
                    key={input.name}
                    name={input.name}
                    className={input.name}
                    ref={(ref) => {
                      if (ref) {
                        elRefs[index] = ref
                      }
                    }}
                    data-testid={input.name}
                    value={setPasswordForm.values[input.name] || ''}
                    onChange={onCodeChange}
                    onBlur={setPasswordForm.handleBlur(input.name)}
                    onKeyUp={(e) => changeFocus(e, index)}
                    autoComplete="off"
                  />
                ))}
              </S.CodeInput>
              <FakeUserInput />
              <FakePasswordInput />
              <TextButton
                style={{ alignSelf: 'flex-end' }}
                underline
                size="small"
                onClick={carousel.slidePrev}
              >
                Reenviar Código
              </TextButton>
            </S.CodeInputWrapper>
            <Button type="button" onClick={carousel.slideNext}>
              Confirmar
            </Button>
          </S.Step>
          <S.Step>
            <header>
              <Heading as="h1">
                Escolha uma maneira de <strong>alterar sua senha</strong>
              </Heading>
              <SupportText>Digite sua nova senha</SupportText>
            </header>
            <div>
              <PasswordField
                style={{
                  marginBottom: '24px'
                }}
                label="Crie uma senha:"
                name="password"
                initialValue={setPasswordForm.values.password}
                onInputChange={setPasswordForm.handleChange('password')}
                onBlur={setPasswordForm.handleBlur('password')}
                error={
                  setPasswordForm.touched.password
                    ? setPasswordForm.errors.password
                    : undefined
                }
              />
              <PasswordField
                label="Confirme sua senha:"
                name="passwordConfirmation"
                initialValue={setPasswordForm.values.passwordConfirmation}
                onInputChange={setPasswordForm.handleChange(
                  'passwordConfirmation'
                )}
                onBlur={setPasswordForm.handleBlur('passwordConfirmation')}
                error={
                  setPasswordForm.touched.passwordConfirmation
                    ? setPasswordForm.errors.passwordConfirmation
                    : undefined
                }
              />
            </div>
            <Button
              disabled={!setPasswordForm.isValid}
              type="button"
              data-testid="btn-change-password"
              onClick={setPasswordForm.submitForm}
            >
              Salvar
            </Button>
          </S.Step>
        </Carousel>
        <Modal
          title="Código enviado com sucesso!"
          show={showGettedCodeModal}
          close={() => setShowGettedCodeModal(false)}
        />
        <Modal
          title="Senha definida com sucesso!"
          show={showConfirmationModal}
          close={() => history.push('/login')}
        />
      </S.Wrapper>
    </>
  )
}

const EmailValidationSchema = yup.object().shape({
  field: yup.string().email().required()
})

const PhoneValidationSchema = yup.object().shape({
  field: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required()
})

const setPasswordValidationSchema = yup.object().shape({
  codeChar1: yup.string().required(),
  codeChar2: yup.string().required(),
  codeChar3: yup.string().required(),
  codeChar4: yup.string().required(),
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação diferente da senha')
    .required()
})

export type GetPasswordCodeFormValues = {
  field: string
  type: 'phone' | 'email'
  role: 'ADMIN' | 'CRMO' | 'HOSPITALIZATION' | 'SECRETARY' | undefined
}

export type SetPasswordFormValues = {
  codeChar1: string
  codeChar2: string
  codeChar3: string
  codeChar4: string
  password: string
  passwordConfirmation: string
}
