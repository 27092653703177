import React, { useState, useEffect } from 'react'

import Heading from 'presentation/shared/components/Heading'

import * as S from './styles'
import TextField from 'presentation/shared/components/TextField'
import Chip from 'presentation/shared/components/Chip'
import Button from 'presentation/shared/components/Button'
import TussTable from 'presentation/hospital/components/TussTable'
import { Cid } from 'domain/entities/cid'
import { Tuss } from 'domain/entities/tuss'
import ActualPage from 'presentation/shared/components/ActualPage'
import { useFormikContext } from 'formik'
import { AddSurgicalOrderFormValues } from '../index'
import { Profile } from 'common/enum/profile'
import { DocumentItem } from 'presentation/shared/components/DocumentsArea/DocumentsAreaModal'
import DocumentsArea from 'presentation/shared/components/DocumentsArea'
import FavoriteButton from 'presentation/shared/components/FavoriteButton'
import Modal from 'presentation/shared/components/Modal'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { SurgeryEquipments } from './SurgeryEquipments'
import { SelectOpme } from './SelectOpme'
import { SelectCme } from './SelectCME'
import { toast } from 'react-toastify'
import MedicalTeam from './MedicalTeamTable'
import AnesthetistTeamTable from './AnesthetistTeamTable'
import SelectField from 'presentation/shared/components/SelectField'
import { useServices } from 'presentation/hooks/use-services'
import TextArea from 'presentation/shared/components/TextArea'

type Props = {
  cidList?: Cid[]
  tussList?: Tuss[]
  submit?: () => void
  goBack?: () => void
  onlyVisualization?: boolean
  addFavorite?: (value: string) => Promise<void>
  profile?: Profile
  updateOrder?: boolean
  defaultOpme?: boolean
  disabled?: boolean
}

export function SelectSurgeryInfo({
  submit,
  cidList,
  tussList,
  goBack,
  onlyVisualization,
  addFavorite,
  profile,
  updateOrder,
  defaultOpme,
  disabled
}: Props) {
  const surgicalOrderService = useServices().surgicalOrder
  const patientService = useServices().patient
  const [patientOrigins, setPatientOrigins] = useState([])
  const [patientClassificationTypes, setPatientClassificationTypes] = useState(
    []
  )
  const [agendaClassifications, setAgendaClassifications] = useState([])
  const { values, handleChange, setFieldValue, isValid, errors } =
    useFormikContext<AddSurgicalOrderFormValues>()
  const [showCombinedSurgery, setShowCombinedSurgery] = useState<boolean>(
    values?.medicalTeamName ? !!values?.medicalTeamName : null
  )
  const [showAllergy, setShowAllergy] = useState<boolean>(
    values?.allergy ? !!values?.allergy : null
  )
  const [showCellSaver, setShowCellSaver] = useState<boolean>(
    values?.cellSaverSpec ? !!values?.cellSaverSpec : null
  )
  const [showScintigraphyTime, setShowScintigraphyTime] = useState<boolean>(
    values?.preliminaryScintigraphyTime
      ? !!values?.preliminaryScintigraphyTime
      : null
  )
  const [showFreezeTime, setShowFreezeTime] = useState<boolean>(
    values?.freezeTime ? !!values?.freezeTime : null
  )
  const [showXrayOfPieceTime, setShowXrayOfPieceTime] = useState<boolean>(
    values?.xrayOfPieceTime ? !!values?.xrayOfPieceTime : null
  )
  const [showAnesthetistTeam, setShowAnesthetistTeam] = useState<string>()
  const [documents, setDocuments] = useState([] as DocumentItem[])
  const [favoriteModal, setFavoriteModal] = useState(false)

  const handleSubmit = () => {
    if (Object.values(errors).length > 0) {
      Object.values(errors).forEach((error: any) => {
        toast.error(error?.description || error)
      })
      return
    }
    if (!values.use_opme) {
      values.opmes = []
    }
    if (submit) submit()
    makeLocalStorageAdapter().set('favorite', {})
  }

  const handleChangeDocuments = (documents: DocumentItem[]) => {
    setFieldValue('documents_attached', documents)
    setDocuments(documents)
  }

  const handleFavoriteModal = (value: string) => {
    if (addFavorite) {
      addFavorite(value)
    }
    setFavoriteModal(false)
  }

  const hospitalRegimesByProfile = () => {
    return (
      hospitalRegimes[profile as keyof typeof hospitalRegimes] ??
      hospitalRegimes['regimes']
    )
  }

  const handlePatientOrigin = (value) => {
    const origin = patientOrigins.find((origin) => origin.id === +value)
    setFieldValue('patientOrigin', origin)
  }

  const handlePatientClassificationType = (value) => {
    const classification = patientClassificationTypes.find(
      (classi) => classi.id === +value
    )
    setFieldValue('patientClassificationType', classification)
  }

  const handleAgendaClassification = (value) => {
    const classification = agendaClassifications.find(
      (classi) => classi.id === value
    )
    setFieldValue('agendaClassification', classification)
  }

  useEffect(() => {
    setDocuments(values?.documents_attached)
  }, [])

  useEffect(() => {
    async function getFieldsData() {
      setPatientClassificationTypes(
        await patientService.getPatientClassificationTypes()
      )
      setPatientOrigins(await patientService.getPatientOrigins())
      setAgendaClassifications(
        await surgicalOrderService.getAgendaClassifications({
          hospital_id: values.hospital.hospital_id
        })
      )
    }
    getFieldsData()
  }, [])

  return (
    <S.Wrapper>
      {!updateOrder && (
        <>
          <ActualPage text="Voltar" onClick={goBack} />
          <Heading size="xlarge" color="primary" as="h1">
            Novo pedido cirúrgico
          </Heading>
        </>
      )}
      <S.HospitalizationWrapper>
        <S.Headers>
          <Heading size="large" color="primary">
            Caráter da reserva
          </Heading>
        </S.Headers>
        <S.GridContainer>
          <S.ChipContainer>
            <label>
              Caráter<span>*</span>
            </label>
            <div>
              {characters.map((character) => (
                <Chip
                  key={character.label}
                  label={character.label}
                  value={character.value}
                  name="hospitalizationType"
                  disabled={onlyVisualization}
                  onCheck={handleChange('hospitalizationType')}
                  checked={values.hospitalizationType === character.value}
                  dataTestId={`hospitalization-type-chip-${character.value}`}
                />
              ))}
            </div>
          </S.ChipContainer>
          {values?.hospitalizationType && profile === 'secretary' && (
            <S.DateContainer>
              <TextField
                label={
                  values.hospitalizationType === 'elective'
                    ? '1ª Sugestão de data'
                    : 'Sugestão de data'
                }
                labelColor="gray"
                type="date"
                showCalendarIcon
                bgColor="mainBg"
                name="expectedDate"
                id="expectedDate"
                onChange={handleChange('expectedDate1')}
                disabled={onlyVisualization}
                defaultValue={values.expectedDate1}
                required={true}
                data-testid="expected-date-procedure-input"
              />
              {values.hospitalizationType === 'elective' && (
                <>
                  <TextField
                    label="2ª Sugestão de data"
                    labelColor="gray"
                    type="date"
                    showCalendarIcon
                    bgColor="mainBg"
                    name="secondExpectedDate"
                    id="secondExpectedDate"
                    onChange={handleChange('expectedDate2')}
                    disabled={onlyVisualization}
                    defaultValue={values.expectedDate2}
                    data-testid="expected-date-procedure-input"
                  />
                  <TextField
                    label="3ª Sugestão de data"
                    labelColor="gray"
                    type="date"
                    showCalendarIcon
                    bgColor="mainBg"
                    name="thirdExpectedDate"
                    id="thirdExpectedDate"
                    onChange={handleChange('expectedDate3')}
                    disabled={onlyVisualization}
                    defaultValue={values.expectedDate3}
                    data-testid="expected-date-procedure-input"
                  />
                </>
              )}
            </S.DateContainer>
          )}
          {profile !== 'RECEPTIONIST' && (
            <>
              <S.UtiContainer>
                <div
                  style={{
                    marginRight: '2rem',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <label>
                    Uso de UTI<span>*</span>
                  </label>
                  <div
                    style={{ display: 'flex', gap: '12px', marginTop: '10px' }}
                  >
                    {needIcu.map((option) => (
                      <Chip
                        key={option.label}
                        dataTestId={`${option.value}`}
                        label={option.label}
                        customValue={option.value}
                        disabled={onlyVisualization}
                        name="need-icu"
                        checked={option.value === values.needIcu}
                        onCheck={() =>
                          setFieldValue(
                            'needIcu',
                            option.value === values.needIcu
                              ? undefined
                              : option.value
                          )
                        }
                        id={'need-icu-' + option.value}
                      />
                    ))}
                  </div>
                </div>
                {values?.needIcu && (
                  <TextField
                    label="Quantidade de diárias de UTI"
                    labelColor="gray"
                    onInputChange={handleChange('requestedUtiDiaries')}
                    value={values.requestedUtiDiaries || 0}
                    inputSize="6rem"
                    name="requestedUtiDiaries"
                    id="requestedUtiDiaries"
                    onChange={handleChange('requestedUtiDiaries')}
                    type="number"
                    bgColor="mainBg"
                    required
                    min={1}
                    data-testid={`icu-days`}
                  />
                )}
              </S.UtiContainer>
              <TextField
                label="Duração prevista"
                labelColor="gray"
                type="number"
                bgColor="mainBg"
                name="expectedDuration"
                id="expectedDuration"
                required={true}
                disabled={onlyVisualization}
                placeholder="Minutos"
                onChange={handleChange('expectedDuration')}
                defaultValue={values.expectedDuration}
                min={1}
                style={{
                  marginBottom: '3rem'
                }}
              />
            </>
          )}
        </S.GridContainer>

        <S.GridContainer>
          <S.ChipContainer>
            <label>
              Regime Hospitalar<span>*</span>
            </label>
            <div>
              {hospitalRegimesByProfile().map((regime) => (
                <Chip
                  key={regime.label}
                  label={regime.label}
                  value={regime.value}
                  name="hospitalizationMode"
                  onCheck={handleChange('hospitalizationMode')}
                  disabled={onlyVisualization}
                  checked={values.hospitalizationMode === regime.value}
                  dataTestId={`hospital-regimes-chip-${regime.value}`}
                />
              ))}
            </div>
          </S.ChipContainer>
          {profile !== 'RECEPTIONIST' && (
            <>
              <S.ChipContainer>
                <label>
                  Paciente já internado?<span>*</span>
                </label>
                <div>
                  {isHospitalized.map((hospitalized) => (
                    <Chip
                      key={hospitalized.label + 'patientHospitalized'}
                      label={hospitalized.label}
                      customValue={hospitalized.value}
                      name="patientHospitalized"
                      id={'patientHospitalized-' + hospitalized.value}
                      disabled={onlyVisualization}
                      onCheck={() =>
                        setFieldValue(
                          'patientHospitalized',
                          hospitalized.value === values.patientHospitalized
                            ? undefined
                            : hospitalized.value
                        )
                      }
                      checked={
                        hospitalized.value === values.patientHospitalized
                      }
                      dataTestId={`patient-hospitalized-chip-${hospitalized.value}`}
                    />
                  ))}
                </div>
              </S.ChipContainer>
              <TextField
                label="Tempo médio de internação"
                labelColor="gray"
                type="number"
                bgColor="mainBg"
                name="hospitalizationAvgTimeInDays"
                id="hospitalizationAvgTimeInDays"
                required={true}
                disabled={onlyVisualization}
                placeholder="Dias"
                onChange={handleChange('hospitalizationAvgTimeInDays')}
                defaultValue={values.hospitalizationAvgTimeInDays}
                data-testid={`other-allergy-input`}
                min={1}
              />
            </>
          )}
        </S.GridContainer>
        {profile === 'crmo' ? (
          <S.GridContainer
            style={{
              marginTop: '1.5rem'
            }}
          >
            <SelectField
              name="patientOrigin"
              label="Origem do paciente"
              items={[
                { value: null, label: 'Selecionar' },
                ...patientOrigins.map((origin) => ({
                  value: origin.id,
                  label: origin.description
                }))
              ]}
              labelColor="gray"
              onInputChange={(e) => handlePatientOrigin(e)}
              value={values?.patientOrigin?.id}
              bgColor="mainBg"
              disabled={onlyVisualization}
              required
            />
            <SelectField
              name="patientClassificationType"
              label="Tipo de classificação do paciente"
              items={[
                { value: null, label: 'Selecionar' },
                ...patientClassificationTypes.map((origin) => ({
                  value: origin.id,
                  label: origin.description
                }))
              ]}
              labelColor="gray"
              onInputChange={(e) => handlePatientClassificationType(e)}
              value={values?.patientClassificationType?.id}
              bgColor="mainBg"
              disabled={onlyVisualization}
              required
            />
            <SelectField
              name="agendaClassification"
              label="Tipo de classificação da agenda"
              items={[
                { value: null, label: 'Selecionar' },
                ...agendaClassifications.map((origin) => ({
                  value: origin.id,
                  label: origin.description
                }))
              ]}
              labelColor="gray"
              onInputChange={(e) => handleAgendaClassification(e)}
              value={values?.agendaClassification?.id}
              bgColor="mainBg"
              disabled={onlyVisualization}
              required
            />
          </S.GridContainer>
        ) : (
          <S.GridContainer
            style={{
              marginTop: '1.5rem'
            }}
          >
            <SelectField
              name="agendaClassification"
              label="Tipo de classificação da agenda"
              items={[
                { value: null, label: 'Selecionar' },
                ...agendaClassifications.map((origin) => ({
                  value: origin.id,
                  label: origin.description
                }))
              ]}
              labelColor="gray"
              onInputChange={(e) => handleAgendaClassification(e)}
              value={values?.agendaClassification?.id}
              bgColor="mainBg"
              disabled={onlyVisualization}
              required
            />
          </S.GridContainer>
        )}
      </S.HospitalizationWrapper>
      <div style={{ display: 'flex' }}>
        <Heading
          size="large"
          style={{ marginTop: '3rem', marginBottom: '1.25rem', flex: 1 }}
          color="primary"
        >
          Procedimento
        </Heading>
      </div>
      <S.ChipContainer style={{ marginBottom: '1.75rem' }}>
        <label htmlFor="medicalTeam">Cirurgia conjugada</label>
        <div>
          {medicalTeam.map((option) => (
            <Chip
              key={option.label}
              dataTestId={`${option.value}`}
              label={option.label}
              customValue={option.value}
              disabled={onlyVisualization}
              name="medical-team"
              onCheck={() => setShowCombinedSurgery(option.value)}
              checked={option.value === showCombinedSurgery}
              id={'medical-team-' + option.value}
            />
          ))}
          {showCombinedSurgery && (
            <TextField
              bgColor="mainBg"
              size={50}
              disabled={onlyVisualization}
              placeholder="Qual equipe"
              name="medicalTeamName"
              id="medicalTeamName"
              onChange={handleChange('medicalTeamName')}
              defaultValue={values.medicalTeamName}
              data-testid={`other-allergy-input`}
            />
          )}
        </div>
      </S.ChipContainer>
      <Modal
        title="Nome do favorito"
        description="Favorite um procedimento para agilizar a construção dos próximos pedidos cirúrgicos"
        show={favoriteModal}
        preventAutomateClose
        type="favorite"
        hasInput={(value) => handleFavoriteModal(value)}
        bgZIndex={12}
        actionLabel="Salvar"
        close={() => setFavoriteModal(!favoriteModal)}
        subtitleProps={{
          colorFont: 'black'
        }}
        style={{ padding: '24px', width: 400 }}
      />
      <TussTable
        tussList={tussList}
        cidList={cidList}
        doctorName={values.doctor?.name}
        onlyVisualization={onlyVisualization}
        cid={values.cids}
        setCid={(cid) => setFieldValue('cids', cid)}
        clinicalHistory={values.clinicalHistory}
        selectedProcedures={values.procedures}
        selectedCids={values.cids}
        setSelectedProcedures={(procedure) =>
          setFieldValue('procedures', procedure)
        }
        setSelectedCids={(cid) => setFieldValue('cids', cid)}
        setClinicalHistory={(clinicalHistory) =>
          setFieldValue('clinicalHistory', clinicalHistory)
        }
        profile={profile}
      />
      <Heading
        size="large"
        style={{ marginTop: '3rem', marginBottom: '1.25rem' }}
        color="primary"
      >
        Dados Cirúrgicos
      </Heading>
      <S.SurgeryWrapper>
        <section>
          <S.ChipContainer>
            <label>Cintilografia prévia</label>
            <div>
              {scintigraphy.map((option) => (
                <Chip
                  key={option.label + 'scintigraphy'}
                  label={option.label}
                  customValue={option.value}
                  disabled={onlyVisualization}
                  name="scintigraphy"
                  onCheck={() => setShowScintigraphyTime(option.value)}
                  checked={option.value === showScintigraphyTime}
                  id={'scintigraphy-' + option.value}
                />
              ))}
              {showScintigraphyTime && (
                <TextField
                  bgColor="mainBg"
                  disabled={onlyVisualization}
                  placeholder="Hora"
                  inputSize="10rem"
                  name="preliminaryScintigraphyTime"
                  id="preliminaryScintigraphyTime"
                  onChange={handleChange('preliminaryScintigraphyTime')}
                  required
                  defaultValue={values.preliminaryScintigraphyTime}
                />
              )}
            </div>
          </S.ChipContainer>
          <S.ChipContainer>
            <label>Lateralidade</label>
            <div>
              {laterality.map((laterality) => (
                <Chip
                  key={laterality.label + 'laterality'}
                  label={laterality.label}
                  customValue={laterality.value}
                  disabled={onlyVisualization}
                  name="laterality"
                  onCheck={() =>
                    setFieldValue(
                      'laterality',
                      laterality.value === values.laterality
                        ? undefined
                        : laterality.value
                    )
                  }
                  checked={values.laterality === laterality.value}
                  id={'freeze-' + laterality.value}
                />
              ))}
            </div>
          </S.ChipContainer>
        </section>
        <section>
          <S.ChipContainer>
            <label>Congelação</label>
            <div>
              {freezing.map((option) => (
                <Chip
                  key={option.label + 'freezing'}
                  label={option.label}
                  customValue={option.value}
                  disabled={onlyVisualization}
                  name="freeze"
                  onCheck={() => setShowFreezeTime(option.value)}
                  checked={option.value === showFreezeTime}
                  id={'freeze-' + option.value}
                />
              ))}
              {showFreezeTime && (
                <TextField
                  bgColor="mainBg"
                  disabled={onlyVisualization}
                  placeholder="Hora"
                  inputSize="10rem"
                  name="freezeTime"
                  id="freezeTime"
                  onChange={handleChange('freezeTime')}
                  required
                  defaultValue={values.freezeTime}
                />
              )}
            </div>
          </S.ChipContainer>
          <S.ChipContainer>
            <label>Raio-X da peça</label>
            <div>
              {xRayOfPiece.map((option) => (
                <Chip
                  key={option.label + 'xRayOfPiece'}
                  label={option.label}
                  customValue={option.value}
                  disabled={onlyVisualization}
                  name="xRayOfPiece"
                  onCheck={() => setShowXrayOfPieceTime(option.value)}
                  checked={option.value === showXrayOfPieceTime}
                  id={'xRayOfPiece-' + option.value}
                />
              ))}
              {showXrayOfPieceTime && (
                <TextField
                  bgColor="mainBg"
                  disabled={onlyVisualization}
                  placeholder="Hora"
                  inputSize="10rem"
                  name="xrayOfPieceTime"
                  id="xrayOfPieceTime"
                  onChange={handleChange('xrayOfPieceTime')}
                  required
                  defaultValue={values.xrayOfPieceTime}
                />
              )}
            </div>
          </S.ChipContainer>
          <S.ChipContainer>
            <label htmlFor="allergy">
              Alergias<span>*</span>
            </label>
            <div>
              {allergies.map((option) => (
                <Chip
                  key={option.label}
                  dataTestId={`${option.value}`}
                  label={option.label}
                  customValue={option.value}
                  disabled={onlyVisualization}
                  name="allergy"
                  onCheck={() => {
                    setShowAllergy(option.value)
                    if (!option.value) {
                      setFieldValue('allergy', ' ')
                    } else {
                      setFieldValue('allergy', '')
                    }
                  }}
                  checked={option.value === showAllergy}
                  id={'allergy-' + option.value}
                />
              ))}
              {showAllergy && (
                <TextField
                  bgColor="mainBg"
                  disabled={onlyVisualization}
                  placeholder="Especificar alergia"
                  name="allergy"
                  id="allergy"
                  onChange={handleChange('allergy')}
                  required
                  defaultValue={values.allergy}
                  data-testid={`allergy`}
                />
              )}
            </div>
          </S.ChipContainer>
        </section>
      </S.SurgeryWrapper>
      <MedicalTeam
        doctorName={values.doctor?.name}
        onlyVisualization={onlyVisualization}
        selectedMedicalTeam={values.medicalTeam}
        setSelectedMedicalTeam={(medicalTeam) =>
          setFieldValue('medicalTeam', medicalTeam)
        }
      />
      <S.ChipContainer style={{ marginTop: '1.75rem' }}>
        <label>Anestesia</label>
        <div>
          {anesthesia.map((option) => (
            <Chip
              style={{ marginTop: '0' }}
              key={option.label + 'anesthesia'}
              label={option.label}
              customValue={option.value}
              name="anesthesia"
              id={'anesthesia-' + option.value}
              disabled={onlyVisualization}
              onCheck={() => setShowAnesthetistTeam(option.value)}
              checked={option.value === showAnesthetistTeam}
            />
          ))}
        </div>
      </S.ChipContainer>
      {showAnesthetistTeam === 'external' && (
        <AnesthetistTeamTable
          doctorName={values.doctor?.name}
          onlyVisualization={onlyVisualization}
          selectedAnesthetistTeam={values.anesthetistTeam}
          setSelectedAnesthetistTeam={(anesthetistTeam) =>
            setFieldValue('anesthetistTeam', anesthetistTeam)
          }
        />
      )}
      <Heading
        size="large"
        style={{ marginTop: '3rem', marginBottom: '1.25rem' }}
        color="primary"
      >
        Banco de sangue
      </Heading>
      <S.BloodTypeContainer>
        <S.ChipContainer>
          <label>Tipagem Sanguínea</label>
          <div>
            {bloodType.map((option) => (
              <Chip
                style={{ marginTop: '0' }}
                key={option.label + 'bloodType'}
                label={option.label}
                customValue={option.value}
                name="bloodType"
                id={'bloodType-' + option.value}
                disabled={onlyVisualization}
                onCheck={() =>
                  setFieldValue(
                    'hasBloodType',
                    option.value === values.hasBloodType
                      ? undefined
                      : option.value
                  )
                }
                checked={option.value === values.hasBloodType}
              />
            ))}
          </div>
        </S.ChipContainer>
        <S.ChipContainer>
          <label>Hemoconcentrados</label>
          <div>
            {hemoconcentrate.map((option) => (
              <Chip
                style={{ marginTop: '0' }}
                key={option.label + 'hemoconcentrate'}
                label={option.label}
                customValue={option.value}
                name="hemoconcentrate"
                id={'hemoconcentrate-' + option.value}
                disabled={onlyVisualization}
                onCheck={() =>
                  setFieldValue(
                    'hasHemoconcentrates',
                    option.value === values.hasHemoconcentrates
                      ? undefined
                      : option.value
                  )
                }
                checked={option.value === values.hasHemoconcentrates}
              />
            ))}
          </div>
        </S.ChipContainer>
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
          <S.ChipContainer>
            <label>Cell Saver</label>
            <div>
              {cellSaver.map((option) => (
                <Chip
                  style={{ marginTop: '0' }}
                  key={option.label + 'cellSaver'}
                  label={option.label}
                  customValue={option.value}
                  name="cellSaver"
                  id={'cellSaver-' + option.value}
                  disabled={onlyVisualization}
                  onCheck={() => setShowCellSaver(option.value)}
                  checked={option.value === showCellSaver}
                />
              ))}
              {showCellSaver && (
                <TextField
                  bgColor="mainBg"
                  disabled={onlyVisualization}
                  placeholder="Especificação"
                  name="cellSaverSpec"
                  id="cellSaverSpec"
                  onChange={handleChange('cellSaverSpec')}
                  defaultValue={values.cellSaverSpec}
                  data-testid={`cellSaverSpec`}
                />
              )}
            </div>
          </S.ChipContainer>
          <S.ChipContainer>
            <label>Quantidade de bolsas</label>
            <div>
              <TextField
                bgColor="mainBg"
                disabled={onlyVisualization}
                name="bloodBagQuantity"
                id="bloodBagQuantity"
                type="number"
                inputSize="6rem"
                onChange={handleChange('bloodBagQuantity')}
                defaultValue={values.bloodBagQuantity || 0}
                data-testid={`bloodBagQuantity`}
                min={0}
              />
            </div>
          </S.ChipContainer>
        </div>
      </S.BloodTypeContainer>
      <SurgeryEquipments />
      <SelectOpme
        defaultOpme={defaultOpme}
        onlyVisualization={onlyVisualization}
        profile={profile}
      />
      {profile !== Profile.SECRETARY && (
        <>
          <div style={{ marginTop: '20px' }}>
            <Heading
              size="large"
              style={{ marginTop: '3rem', marginBottom: '1.25rem' }}
              color="primary"
            >
              Observações
            </Heading>
            <div>
              <TextArea
                bgColor="mainBg"
                style={{ width: '100%' }}
                rows={5}
                onChange={handleChange('observations')}
                value={values?.observations}
                disabled={onlyVisualization}
                dataTestId={`observations-input`}
              />
            </div>
          </div>
          <br />
        </>
      )}

      <SelectCme onlyVisualization={onlyVisualization} />
      {!updateOrder && (
        <DocumentsArea
          defaultDocuments={documents}
          handleChangeDocuments={(documents: DocumentItem[]) =>
            handleChangeDocuments(documents)
          }
          canSee={values.canSee}
          preventCreateExam={false}
        />
      )}
      <S.Buttons>
        {!!addFavorite && (
          <FavoriteButton
            data-testid="btn-procedure-favorite"
            onClick={() => setFavoriteModal(!favoriteModal)}
            disabled={
              !isValid || (values.use_opme && values.opmes?.length === 0)
            }
            style={{
              padding: 0,
              height: 'unset'
            }}
          />
        )}

        <Button
          onClick={onlyVisualization ? goBack : handleSubmit}
          type="button"
          data-testid={`go-back-or-next-button`}
        >
          {onlyVisualization ? 'Voltar' : 'Ver resumo'}
        </Button>

        {updateOrder && (
          <Button
            onClick={handleSubmit}
            type="button"
            disabled={disabled}
            data-testid={`save-button`}
          >
            Salvar
          </Button>
        )}
      </S.Buttons>
    </S.Wrapper>
  )
}

export default SelectSurgeryInfo

const freezing = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não',
    value: false
  }
]

const scintigraphy = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não',
    value: false
  }
]

const xRayOfPiece = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não',
    value: false
  }
]

const laterality = [
  {
    label: 'Esquerdo',
    value: 'left'
  },
  {
    label: 'Direito',
    value: 'right'
  },
  {
    label: 'Bilateral',
    value: 'bilateral'
  },
  {
    label: 'Não se aplica',
    value: 'not applicable'
  }
]

const allergies = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não informado',
    value: false
  }
]

const hospitalRegimes = {
  regimes: [
    {
      label: 'Hospitalar',
      value: 'hospital'
    },
    {
      label: 'Hospital dia',
      value: 'day hospital'
    },
    {
      label: 'Ambulatorial',
      value: 'ambulatory'
    }
  ],
  patient: [],
  doctor: [],
  admin: [],
  hospitalization: [],
  ambulatory: [],
  doctor_register: [],
  director_doctor_register: [],
  admin_sectorial_crmo: []
}

const characters = [
  {
    label: 'Eletivo',
    value: 'elective'
  },
  {
    label: 'Urgência',
    value: 'urgency'
  },
  {
    label: 'Emergência',
    value: 'emergency'
  }
]

const isHospitalized = [
  {
    label: 'Não',
    value: false
  },
  {
    label: 'Sim',
    value: true
  }
]

const hemoconcentrate = [
  {
    label: 'Não',
    value: false
  },
  {
    label: 'Sim',
    value: true
  }
]

const bloodType = [
  {
    label: 'Não',
    value: false
  },
  {
    label: 'Sim',
    value: true
  }
]

const cellSaver = [
  {
    label: 'Não',
    value: false
  },
  {
    label: 'Sim',
    value: true
  }
]

const anesthesia = [
  {
    label: 'Casa',
    value: 'recurrent'
  },
  {
    label: 'Externo',
    value: 'external'
  }
]

const medicalTeam = [
  {
    label: 'Não',
    value: false
  },
  {
    label: 'Sim',
    value: true
  }
]

const needIcu = [
  {
    label: 'Não',
    value: false
  },
  {
    label: 'Sim',
    value: true
  }
]
